/* tslint:disable */
/* eslint-disable */
/**
 * KpiResult
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AiKpiResult
 */
export interface AiKpiResult {
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'lastModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'kpi1'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'kpi2'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'kpi3'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'kpi4'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'mAP'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiKpiResult
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Array<AiStepwiseScore>}
     * @memberof AiKpiResult
     */
    'steps'?: Array<AiStepwiseScore>;
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'session'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiKpiResult
     */
    'kpi5'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'gtRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiKpiResult
     */
    'predRef'?: string;
    /**
     * 
     * @type {Array<AiStepwiseScore>}
     * @memberof AiKpiResult
     */
    'kpi1Breakdown'?: Array<AiStepwiseScore>;
    /**
     * 
     * @type {Array<AiStepwiseScore>}
     * @memberof AiKpiResult
     */
    'kpi2Breakdown'?: Array<AiStepwiseScore>;
    /**
     * 
     * @type {Array<AiStepwiseCount>}
     * @memberof AiKpiResult
     */
    'kpi3Counts'?: Array<AiStepwiseCount>;
    /**
     * 
     * @type {Array<AiStepwiseScore>}
     * @memberof AiKpiResult
     */
    'kpi4Breakdown'?: Array<AiStepwiseScore>;
}
/**
 * 
 * @export
 * @interface AiListKpiResultsResponse
 */
export interface AiListKpiResultsResponse {
    /**
     * 
     * @type {Array<AiKpiResult>}
     * @memberof AiListKpiResultsResponse
     */
    'kpiResults'?: Array<AiKpiResult>;
    /**
     * 
     * @type {string}
     * @memberof AiListKpiResultsResponse
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface AiStepwiseCount
 */
export interface AiStepwiseCount {
    /**
     * 
     * @type {string}
     * @memberof AiStepwiseCount
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiStepwiseCount
     */
    'truePositives'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStepwiseCount
     */
    'falsePositives'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStepwiseCount
     */
    'trueNegatives'?: number;
}
/**
 * 
 * @export
 * @interface AiStepwiseScore
 */
export interface AiStepwiseScore {
    /**
     * 
     * @type {string}
     * @memberof AiStepwiseScore
     */
    'step'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiStepwiseScore
     */
    'stepId'?: string;
    /**
     * 
     * @type {StepwiseScoreClassification}
     * @memberof AiStepwiseScore
     */
    'classification'?: StepwiseScoreClassification;
    /**
     * 
     * @type {number}
     * @memberof AiStepwiseScore
     */
    'frame'?: number;
}


/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StepwiseScoreClassification = {
    Unset: 'UNSET',
    TruePositive: 'TRUE_POSITIVE',
    TrueNegative: 'TRUE_NEGATIVE',
    FalsePositive: 'FALSE_POSITIVE',
    FalseNegative: 'FALSE_NEGATIVE'
} as const;

export type StepwiseScoreClassification = typeof StepwiseScoreClassification[keyof typeof StepwiseScoreClassification];



/**
 * KpiResultsApi - axios parameter creator
 * @export
 */
export const KpiResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} spotlight 
         * @param {AiKpiResult} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsCreateKpiResult: async (spotlight: string, body: AiKpiResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('kpiResultsCreateKpiResult', 'spotlight', spotlight)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('kpiResultsCreateKpiResult', 'body', body)
            const localVarPath = `/v1/spotlights/{spotlight}/kpiresults`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsDeleteKpiResult: async (spotlight: string, kpiresult: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('kpiResultsDeleteKpiResult', 'spotlight', spotlight)
            // verify required parameter 'kpiresult' is not null or undefined
            assertParamExists('kpiResultsDeleteKpiResult', 'kpiresult', kpiresult)
            const localVarPath = `/v1/spotlights/{spotlight}/kpiresults/{kpiresult}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)))
                .replace(`{${"kpiresult"}}`, encodeURIComponent(String(kpiresult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsGetKpiResult: async (spotlight: string, kpiresult: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('kpiResultsGetKpiResult', 'spotlight', spotlight)
            // verify required parameter 'kpiresult' is not null or undefined
            assertParamExists('kpiResultsGetKpiResult', 'kpiresult', kpiresult)
            const localVarPath = `/v1/spotlights/{spotlight}/kpiresults/{kpiresult}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)))
                .replace(`{${"kpiresult"}}`, encodeURIComponent(String(kpiresult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsListKpiResults: async (spotlight: string, pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('kpiResultsListKpiResults', 'spotlight', spotlight)
            const localVarPath = `/v1/spotlights/{spotlight}/kpiresults`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (readMask !== undefined) {
                localVarQueryParameter['readMask'] = readMask;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {AiKpiResult} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsUpdateKpiResult: async (spotlight: string, kpiresult: string, body: AiKpiResult, updateMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('kpiResultsUpdateKpiResult', 'spotlight', spotlight)
            // verify required parameter 'kpiresult' is not null or undefined
            assertParamExists('kpiResultsUpdateKpiResult', 'kpiresult', kpiresult)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('kpiResultsUpdateKpiResult', 'body', body)
            const localVarPath = `/v1/spotlights/{spotlight}/kpiresults/{kpiresult}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)))
                .replace(`{${"kpiresult"}}`, encodeURIComponent(String(kpiresult)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (updateMask !== undefined) {
                localVarQueryParameter['updateMask'] = updateMask;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KpiResultsApi - functional programming interface
 * @export
 */
export const KpiResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KpiResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} spotlight 
         * @param {AiKpiResult} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiResultsCreateKpiResult(spotlight: string, body: AiKpiResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiKpiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiResultsCreateKpiResult(spotlight, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiResultsDeleteKpiResult(spotlight: string, kpiresult: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiResultsDeleteKpiResult(spotlight, kpiresult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiResultsGetKpiResult(spotlight: string, kpiresult: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiKpiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiResultsGetKpiResult(spotlight, kpiresult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiResultsListKpiResults(spotlight: string, pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiListKpiResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiResultsListKpiResults(spotlight, pageSize, pageToken, filter, orderBy, readMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {string} kpiresult 
         * @param {AiKpiResult} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kpiResultsUpdateKpiResult(spotlight: string, kpiresult: string, body: AiKpiResult, updateMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiKpiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kpiResultsUpdateKpiResult(spotlight, kpiresult, body, updateMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KpiResultsApi - factory interface
 * @export
 */
export const KpiResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KpiResultsApiFp(configuration)
    return {
        /**
         * 
         * @param {KpiResultsApiKpiResultsCreateKpiResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsCreateKpiResult(requestParameters: KpiResultsApiKpiResultsCreateKpiResultRequest, options?: AxiosRequestConfig): AxiosPromise<AiKpiResult> {
            return localVarFp.kpiResultsCreateKpiResult(requestParameters.spotlight, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KpiResultsApiKpiResultsDeleteKpiResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsDeleteKpiResult(requestParameters: KpiResultsApiKpiResultsDeleteKpiResultRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.kpiResultsDeleteKpiResult(requestParameters.spotlight, requestParameters.kpiresult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KpiResultsApiKpiResultsGetKpiResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsGetKpiResult(requestParameters: KpiResultsApiKpiResultsGetKpiResultRequest, options?: AxiosRequestConfig): AxiosPromise<AiKpiResult> {
            return localVarFp.kpiResultsGetKpiResult(requestParameters.spotlight, requestParameters.kpiresult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KpiResultsApiKpiResultsListKpiResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsListKpiResults(requestParameters: KpiResultsApiKpiResultsListKpiResultsRequest, options?: AxiosRequestConfig): AxiosPromise<AiListKpiResultsResponse> {
            return localVarFp.kpiResultsListKpiResults(requestParameters.spotlight, requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KpiResultsApiKpiResultsUpdateKpiResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiResultsUpdateKpiResult(requestParameters: KpiResultsApiKpiResultsUpdateKpiResultRequest, options?: AxiosRequestConfig): AxiosPromise<AiKpiResult> {
            return localVarFp.kpiResultsUpdateKpiResult(requestParameters.spotlight, requestParameters.kpiresult, requestParameters.body, requestParameters.updateMask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for kpiResultsCreateKpiResult operation in KpiResultsApi.
 * @export
 * @interface KpiResultsApiKpiResultsCreateKpiResultRequest
 */
export interface KpiResultsApiKpiResultsCreateKpiResultRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsCreateKpiResult
     */
    readonly spotlight: string

    /**
     * 
     * @type {AiKpiResult}
     * @memberof KpiResultsApiKpiResultsCreateKpiResult
     */
    readonly body: AiKpiResult
}

/**
 * Request parameters for kpiResultsDeleteKpiResult operation in KpiResultsApi.
 * @export
 * @interface KpiResultsApiKpiResultsDeleteKpiResultRequest
 */
export interface KpiResultsApiKpiResultsDeleteKpiResultRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsDeleteKpiResult
     */
    readonly spotlight: string

    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsDeleteKpiResult
     */
    readonly kpiresult: string
}

/**
 * Request parameters for kpiResultsGetKpiResult operation in KpiResultsApi.
 * @export
 * @interface KpiResultsApiKpiResultsGetKpiResultRequest
 */
export interface KpiResultsApiKpiResultsGetKpiResultRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsGetKpiResult
     */
    readonly spotlight: string

    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsGetKpiResult
     */
    readonly kpiresult: string
}

/**
 * Request parameters for kpiResultsListKpiResults operation in KpiResultsApi.
 * @export
 * @interface KpiResultsApiKpiResultsListKpiResultsRequest
 */
export interface KpiResultsApiKpiResultsListKpiResultsRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly spotlight: string

    /**
     * How many events to return per page.
     * @type {number}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly pageSize?: number

    /**
     * Used to retrieve the next page.
     * @type {string}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly pageToken?: string

    /**
     * comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
     * @type {string}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly filter?: string

    /**
     * order by description ref: https://google.aip.dev/160 * manufacturer * line.
     * @type {string}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsListKpiResults
     */
    readonly readMask?: string
}

/**
 * Request parameters for kpiResultsUpdateKpiResult operation in KpiResultsApi.
 * @export
 * @interface KpiResultsApiKpiResultsUpdateKpiResultRequest
 */
export interface KpiResultsApiKpiResultsUpdateKpiResultRequest {
    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsUpdateKpiResult
     */
    readonly spotlight: string

    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsUpdateKpiResult
     */
    readonly kpiresult: string

    /**
     * 
     * @type {AiKpiResult}
     * @memberof KpiResultsApiKpiResultsUpdateKpiResult
     */
    readonly body: AiKpiResult

    /**
     * 
     * @type {string}
     * @memberof KpiResultsApiKpiResultsUpdateKpiResult
     */
    readonly updateMask?: string
}

/**
 * KpiResultsApi - object-oriented interface
 * @export
 * @class KpiResultsApi
 * @extends {BaseAPI}
 */
export class KpiResultsApi extends BaseAPI {
    /**
     * 
     * @param {KpiResultsApiKpiResultsCreateKpiResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiResultsApi
     */
    public kpiResultsCreateKpiResult(requestParameters: KpiResultsApiKpiResultsCreateKpiResultRequest, options?: AxiosRequestConfig) {
        return KpiResultsApiFp(this.configuration).kpiResultsCreateKpiResult(requestParameters.spotlight, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KpiResultsApiKpiResultsDeleteKpiResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiResultsApi
     */
    public kpiResultsDeleteKpiResult(requestParameters: KpiResultsApiKpiResultsDeleteKpiResultRequest, options?: AxiosRequestConfig) {
        return KpiResultsApiFp(this.configuration).kpiResultsDeleteKpiResult(requestParameters.spotlight, requestParameters.kpiresult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KpiResultsApiKpiResultsGetKpiResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiResultsApi
     */
    public kpiResultsGetKpiResult(requestParameters: KpiResultsApiKpiResultsGetKpiResultRequest, options?: AxiosRequestConfig) {
        return KpiResultsApiFp(this.configuration).kpiResultsGetKpiResult(requestParameters.spotlight, requestParameters.kpiresult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KpiResultsApiKpiResultsListKpiResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiResultsApi
     */
    public kpiResultsListKpiResults(requestParameters: KpiResultsApiKpiResultsListKpiResultsRequest, options?: AxiosRequestConfig) {
        return KpiResultsApiFp(this.configuration).kpiResultsListKpiResults(requestParameters.spotlight, requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KpiResultsApiKpiResultsUpdateKpiResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiResultsApi
     */
    public kpiResultsUpdateKpiResult(requestParameters: KpiResultsApiKpiResultsUpdateKpiResultRequest, options?: AxiosRequestConfig) {
        return KpiResultsApiFp(this.configuration).kpiResultsUpdateKpiResult(requestParameters.spotlight, requestParameters.kpiresult, requestParameters.body, requestParameters.updateMask, options).then((request) => request(this.axios, this.basePath));
    }
}


