const BASE_PATH = `${window.location.origin}/v1`

export const getSession = {
  url: `${BASE_PATH}/sessions/fd047fca-2e93-4ffe-acfb-c0e90096815f`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./session-item.mock.json'),
}

export const getSessionsList = {
  url: `${BASE_PATH}/sessions?pageSize=-1&filter=%7B%22%24and%22%3A%5B%7B%22lastModified.seconds%22%3A%7B%22%24gte%22%3A1666270740%7D%7D%2C%7B%22lastModified.seconds%22%3A%7B%22%24lte%22%3A1666357140%7D%7D%2C%7B%22source%22%3A%7B%22%24ne%22%3A%22sherman-oaks%22%7D%7D%2C%7B%22type%22%3A2%7D%5D%7D&orderBy=station%2Cprocedure`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./sessions.mock.json'),
}

export const getStationsList = {
  url: `${BASE_PATH}/stations`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./stations.mock.json'),
}

export const getCamerasList = {
  url: `${BASE_PATH}/stations/test_station/cameras?pageSize=-1&orderBy=-isActive.value`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./cameras.mock.json'),
}

export const getProceduresList = {
  url: `${BASE_PATH}/procedures?pageSize=-1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./procedures.mock.json'),
}

export const getUnitsList = {
  url: `${BASE_PATH}/units?pageSize=-1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./units.mock.json'),
}

export const getAuditSli = {
  url: `${BASE_PATH}/audit_sli/procedures/ardb-stage-3/macrostep/1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./audit-sli.mock.json'),
}

export const getCaptchaImages = {
  url: `${BASE_PATH}/captchaImages?pageSize=-1&filter=%7B%22procedure%22%3A%22procedures%2Ftest-procedure%22%2C%22numberMacro%22%3A%221%22%7D`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./captcha-images.mock.json'),
}

export const getTestDevResolutions = {
  url: `${BASE_PATH}/devresolutions?pageSize=-1&filter=%7B%22%24and%22%3A%5B%7B%22procedure%22%3A%22procedures%2Farda-stage-3%22%7D%2C%7B%22created.seconds%22%3A%7B%22%24gte%22%3A1681938000%7D%7D%2C%7B%22created.seconds%22%3A%7B%22%24lte%22%3A1682024400%7D%7D%5D%7D`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./dev-resolutions.mock.json'),
}

export const getProcedureDevResolutions = {
  url: `${BASE_PATH}/devresolutions?pageSize=-1&filter=%7B%22%24and%22%3A%5B%7B%22created.seconds%22%3A%7B%22%24gte%22%3A1681938000%7D%7D%2C%7B%22created.seconds%22%3A%7B%22%24lte%22%3A1682024400%7D%7D%5D%7D`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./dev-resolutions.mock.json'),
}

export const getTestProcedure = {
  url: `${BASE_PATH}/procedures/arda-stage-3`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./procedure-item.mock.json'),
}

export const getTestSession = {
  url: `${BASE_PATH}/sessions/test-session-1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./session-item.mock.json'),
}

export const getTestUnit = {
  url: `${BASE_PATH}/sessions/test-session-1/units/test-unit-1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./unit-item.mock.json'),
}

export const getTestInferences = {
  url: `${BASE_PATH}/sessions/test-session-1/inferences?readMask=objectDetections%2Cpts`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./inference-item.mock.json'),
}

export const getTestSteps = {
  url: `${BASE_PATH}/sessions/test-session-1/units/test-unit-1/steps`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./steps.mock.json'),
}

export const getTestDevResolution = {
  url: `${BASE_PATH}/devresolutions/test-dev-res-1`,
  method: 'GET',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./dev-resolution-item.mock.json'),
}

export const getTestSliData = {
  url: `${BASE_PATH}/audit_sli/macrostep/1`,
  method: 'POST',
  responseStatus: 200,
  responseBody: async (): Promise<unknown> => await import('./sli-data.mock.json'),
}
