import { datadogRum } from '@datadog/browser-rum'
import VersionService from '@/services/VersionService'

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

export default {
  init: async (): Promise<void> => {
    if (isDevelopmentEnv) return

    const version = await VersionService.getVersion()

    datadogRum.init({
      applicationId: process.env.VUE_APP_DD_APP_ID || '',
      clientToken: process.env.VUE_APP_DD_TOKEN || '',
      site: 'datadoghq.com',
      service: 'cd',
      env: process.env.NODE_ENV || 'development',
      version: version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    })

    datadogRum.startSessionReplayRecording()
  },
}
