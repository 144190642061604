
import { defineComponent, ref, onBeforeMount, watch } from 'vue'
import { useUrlSearchParams } from '@vueuse/core'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { },
  name: 'Search',
  setup() {
    const textInput = ref('')
    const session = ref('')
    const unit = ref('')
    const router = useRouter()

    const onSubmit = () => {
      unit.value = ''
      session.value = ''
      const secs = textInput.value?.split('/')
      if (secs.length === 2) {
        if (secs[0] !== 'sessions') {
          alert('Invalid input')
        } else {
          console.log('session')
          session.value = secs[1]
        }
      } else if (secs.length === 4) {
        if (secs[0] !== 'sessions' && secs[2] !== 'units') {
          alert('Invalid input')
        } else {
          console.log('unit')
          unit.value = textInput.value
          router.push({ path: '/units', query: { name: unit.value } })
        }
      } else {
        alert('Invalid input')
      }
      textInput.value = ''
    }
    onBeforeMount(() => {
      session.value = ''
      unit.value = ''
    })
    // onMounted(() => {
    //   if (urlParams.session) {
    //     session.value = urlParams.session as string
    //   }

    //   if (urlParams.unit) {
    //     unit.value = urlParams.unit as string
    //   }
    // })
    const urlParams = useUrlSearchParams()
    watch([session, unit], () => {
      if (session.value) {
        urlParams.session = session.value
      }

      if (unit.value) {
        urlParams.unit = unit.value
      }
    })
    return { textInput, onSubmit }
  },
})
