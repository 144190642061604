import axios from 'axios'

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

export default {
  getVersion: async (): Promise<string> => {
    if (isDevelopmentEnv) return 'development'

    const response = await axios.get(`${window.location.origin}/version`)
    return response.data
  },
}
