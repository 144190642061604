import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "barmenu" }
const _hoisted_2 = {
  ref: "panel",
  class: "barmenu__panel flex flex-column",
  id: "filter-bar-panel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show)),
      class: "no-bg button"
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(_ctx.icon)
          }, null, 2))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, _ctx.show]
    ])
  ]))
}