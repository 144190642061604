/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { useUserAuthStore } from '@/stores/auth/auth'

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "pmaCloudDashboardApi",
            "endpoint": "https://cloud-dashboard.api.d.pma.nflux.ai",
            "region": "us-west-2",
            "custom_header": async () => {
              return { Authorization: `Bearer ${useUserAuthStore().accessToken}` }
            }
        }
    ],
    "aws_user_files_s3_bucket": "nflux-pma-edge-nokia-chennai",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
