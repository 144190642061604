import { useUserAuthStore, IdClaims } from '@/stores/auth/auth'

export default {
  // TODO: Should there be CognitoUserSession typing?
  // async getSession(): Promise<unknown> {
  //   const session = await Auth.currentSession()
  //
  //   return session
  // },

  async getToken(): Promise<string> {
    const userAuth = useUserAuthStore()
    return userAuth.accessToken || ''
  },

  // async getIdToken(): Promise<string> {
  //   const session = await Auth.currentSession()
  //   const idToken = await session.getIdToken()
  //   const jwtIdToken = idToken.getJwtToken()
  //
  //   return jwtIdToken
  // },

  async getCurrentUser(): Promise<IdClaims> {
    const userAuth = useUserAuthStore()
    return userAuth.idClaims || {} as IdClaims
  },

  async getCurrentUserGroups(): Promise<string[]> {
    const userAuth = useUserAuthStore()
    return userAuth.idClaims?.['custom:groups'] || []
  },
}
