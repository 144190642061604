import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createAuth0 } from '@auth0/auth0-vue'

import Amplify from 'aws-amplify'
import DataDogRumService from '@/services/DataDogRumService'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No annotation for aws-exports file
// TODO: Probably we'll have to annotate it in some closest future
import awsConfig from './aws-exports'

Amplify.configure(awsConfig)
DataDogRumService.init()

applyPolyfills().then(() => {
  // noinspection JSIgnoredPromiseFromCall
  defineCustomElements(window)
})

const app = createApp(App)
const store = createPinia()

export { api } from '@/network'

export default app
  .use(store)
  .use(router)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN || '',
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID || '',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'cloud-dashboard-services',
        scope: 'openid email pma-web-service:read pma-web-service:list mlops-web-service:read mlops-web-service:create mlops-web-service:update mlops-web-service:list cloud-dashboard-api:read cloud-dashboard-api:create cloud-dashboard-api:update cloud-dashboard-api:delete',
      },
      cacheLocation: 'localstorage',
    }),
  )
  .mount('#app')
