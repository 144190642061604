/* tslint:disable */
/* eslint-disable */
/**
 * SpotlightVideo
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AiDataloopItem
 */
export interface AiDataloopItem {
    /**
     * 
     * @type {string}
     * @memberof AiDataloopItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDataloopItem
     */
    'url'?: string;
    /**
     * 
     * @type {DataloopItemDataloopStatus}
     * @memberof AiDataloopItem
     */
    'status'?: DataloopItemDataloopStatus;
    /**
     * 
     * @type {number}
     * @memberof AiDataloopItem
     */
    'numFramesTouched'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiDataloopItem
     */
    'numBboxesTouched'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiDataloopItem
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDataloopItem
     */
    'gtProtoPath'?: string;
}


/**
 * 
 * @export
 * @interface AiListSpotlightVideosResponse
 */
export interface AiListSpotlightVideosResponse {
    /**
     * 
     * @type {Array<AiSpotlightVideo>}
     * @memberof AiListSpotlightVideosResponse
     */
    'spotlightVideos'?: Array<AiSpotlightVideo>;
    /**
     * 
     * @type {string}
     * @memberof AiListSpotlightVideosResponse
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface AiSpotlightVideo
 */
export interface AiSpotlightVideo {
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'videoPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'gtProtoPath'?: string;
    /**
     * 
     * @type {Array<AiDataloopItem>}
     * @memberof AiSpotlightVideo
     */
    'dataloopItems'?: Array<AiDataloopItem>;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'split'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'session'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'step'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'recordedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiSpotlightVideo
     */
    'totalFrames'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiSpotlightVideo
     */
    'numFramesTouched'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiSpotlightVideo
     */
    'numBboxesTouched'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiSpotlightVideo
     */
    'videoRefs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'customer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'procedure'?: string;
    /**
     * 
     * @type {SpotlightVideoMetrics}
     * @memberof AiSpotlightVideo
     */
    'metrics'?: SpotlightVideoMetrics;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'filebase'?: string;
    /**
     * 
     * @type {DataloopItemDataloopStatus}
     * @memberof AiSpotlightVideo
     */
    'overallStatus'?: DataloopItemDataloopStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiSpotlightVideo
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'factory'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSpotlightVideo
     */
    'inferenceVideo'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiSpotlightVideo
     */
    'framePts'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataloopItemDataloopStatus = {
    Unset: 'UNSET',
    Assigned: 'ASSIGNED',
    Discarded: 'DISCARDED',
    Rejected: 'REJECTED',
    Completed: 'COMPLETED',
    Approved: 'APPROVED',
    GtGenerated: 'GT_GENERATED'
} as const;

export type DataloopItemDataloopStatus = typeof DataloopItemDataloopStatus[keyof typeof DataloopItemDataloopStatus];


/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}
/**
 * 
 * @export
 * @interface SpotlightVideoMetrics
 */
export interface SpotlightVideoMetrics {
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'kpi1'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'kpi2'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'kpi3'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'kpi4'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'map'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotlightVideoMetrics
     */
    'kpi5'?: number;
}

/**
 * SpotlightVideosApi - axios parameter creator
 * @export
 */
export const SpotlightVideosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AiSpotlightVideo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosCreateSpotlightVideo: async (body: AiSpotlightVideo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('spotlightVideosCreateSpotlightVideo', 'body', body)
            const localVarPath = `/v1/spotlights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosDeleteSpotlightVideo: async (spotlight: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('spotlightVideosDeleteSpotlightVideo', 'spotlight', spotlight)
            const localVarPath = `/v1/spotlights/{spotlight}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosGetSpotlightVideo: async (spotlight: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('spotlightVideosGetSpotlightVideo', 'spotlight', spotlight)
            const localVarPath = `/v1/spotlights/{spotlight}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosListSpotlightVideos: async (pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/spotlights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (readMask !== undefined) {
                localVarQueryParameter['readMask'] = readMask;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {AiSpotlightVideo} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosUpdateSpotlightVideo: async (spotlight: string, body: AiSpotlightVideo, updateMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotlight' is not null or undefined
            assertParamExists('spotlightVideosUpdateSpotlightVideo', 'spotlight', spotlight)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('spotlightVideosUpdateSpotlightVideo', 'body', body)
            const localVarPath = `/v1/spotlights/{spotlight}`
                .replace(`{${"spotlight"}}`, encodeURIComponent(String(spotlight)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (updateMask !== undefined) {
                localVarQueryParameter['updateMask'] = updateMask;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotlightVideosApi - functional programming interface
 * @export
 */
export const SpotlightVideosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotlightVideosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AiSpotlightVideo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlightVideosCreateSpotlightVideo(body: AiSpotlightVideo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiSpotlightVideo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlightVideosCreateSpotlightVideo(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlightVideosDeleteSpotlightVideo(spotlight: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlightVideosDeleteSpotlightVideo(spotlight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlightVideosGetSpotlightVideo(spotlight: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiSpotlightVideo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlightVideosGetSpotlightVideo(spotlight, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlightVideosListSpotlightVideos(pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiListSpotlightVideosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlightVideosListSpotlightVideos(pageSize, pageToken, filter, orderBy, readMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} spotlight 
         * @param {AiSpotlightVideo} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotlightVideosUpdateSpotlightVideo(spotlight: string, body: AiSpotlightVideo, updateMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiSpotlightVideo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotlightVideosUpdateSpotlightVideo(spotlight, body, updateMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotlightVideosApi - factory interface
 * @export
 */
export const SpotlightVideosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotlightVideosApiFp(configuration)
    return {
        /**
         * 
         * @param {SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosCreateSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest, options?: AxiosRequestConfig): AxiosPromise<AiSpotlightVideo> {
            return localVarFp.spotlightVideosCreateSpotlightVideo(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosDeleteSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.spotlightVideosDeleteSpotlightVideo(requestParameters.spotlight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosGetSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest, options?: AxiosRequestConfig): AxiosPromise<AiSpotlightVideo> {
            return localVarFp.spotlightVideosGetSpotlightVideo(requestParameters.spotlight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosListSpotlightVideos(requestParameters: SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AiListSpotlightVideosResponse> {
            return localVarFp.spotlightVideosListSpotlightVideos(requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotlightVideosUpdateSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest, options?: AxiosRequestConfig): AxiosPromise<AiSpotlightVideo> {
            return localVarFp.spotlightVideosUpdateSpotlightVideo(requestParameters.spotlight, requestParameters.body, requestParameters.updateMask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for spotlightVideosCreateSpotlightVideo operation in SpotlightVideosApi.
 * @export
 * @interface SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest
 */
export interface SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest {
    /**
     * 
     * @type {AiSpotlightVideo}
     * @memberof SpotlightVideosApiSpotlightVideosCreateSpotlightVideo
     */
    readonly body: AiSpotlightVideo
}

/**
 * Request parameters for spotlightVideosDeleteSpotlightVideo operation in SpotlightVideosApi.
 * @export
 * @interface SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest
 */
export interface SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosDeleteSpotlightVideo
     */
    readonly spotlight: string
}

/**
 * Request parameters for spotlightVideosGetSpotlightVideo operation in SpotlightVideosApi.
 * @export
 * @interface SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest
 */
export interface SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosGetSpotlightVideo
     */
    readonly spotlight: string
}

/**
 * Request parameters for spotlightVideosListSpotlightVideos operation in SpotlightVideosApi.
 * @export
 * @interface SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest
 */
export interface SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest {
    /**
     * How many events to return per page.
     * @type {number}
     * @memberof SpotlightVideosApiSpotlightVideosListSpotlightVideos
     */
    readonly pageSize?: number

    /**
     * Used to retrieve the next page.
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosListSpotlightVideos
     */
    readonly pageToken?: string

    /**
     * comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosListSpotlightVideos
     */
    readonly filter?: string

    /**
     * order by description ref: https://google.aip.dev/160 * manufacturer * line.
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosListSpotlightVideos
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosListSpotlightVideos
     */
    readonly readMask?: string
}

/**
 * Request parameters for spotlightVideosUpdateSpotlightVideo operation in SpotlightVideosApi.
 * @export
 * @interface SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest
 */
export interface SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosUpdateSpotlightVideo
     */
    readonly spotlight: string

    /**
     * 
     * @type {AiSpotlightVideo}
     * @memberof SpotlightVideosApiSpotlightVideosUpdateSpotlightVideo
     */
    readonly body: AiSpotlightVideo

    /**
     * 
     * @type {string}
     * @memberof SpotlightVideosApiSpotlightVideosUpdateSpotlightVideo
     */
    readonly updateMask?: string
}

/**
 * SpotlightVideosApi - object-oriented interface
 * @export
 * @class SpotlightVideosApi
 * @extends {BaseAPI}
 */
export class SpotlightVideosApi extends BaseAPI {
    /**
     * 
     * @param {SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotlightVideosApi
     */
    public spotlightVideosCreateSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosCreateSpotlightVideoRequest, options?: AxiosRequestConfig) {
        return SpotlightVideosApiFp(this.configuration).spotlightVideosCreateSpotlightVideo(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotlightVideosApi
     */
    public spotlightVideosDeleteSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosDeleteSpotlightVideoRequest, options?: AxiosRequestConfig) {
        return SpotlightVideosApiFp(this.configuration).spotlightVideosDeleteSpotlightVideo(requestParameters.spotlight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotlightVideosApi
     */
    public spotlightVideosGetSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosGetSpotlightVideoRequest, options?: AxiosRequestConfig) {
        return SpotlightVideosApiFp(this.configuration).spotlightVideosGetSpotlightVideo(requestParameters.spotlight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotlightVideosApi
     */
    public spotlightVideosListSpotlightVideos(requestParameters: SpotlightVideosApiSpotlightVideosListSpotlightVideosRequest = {}, options?: AxiosRequestConfig) {
        return SpotlightVideosApiFp(this.configuration).spotlightVideosListSpotlightVideos(requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotlightVideosApi
     */
    public spotlightVideosUpdateSpotlightVideo(requestParameters: SpotlightVideosApiSpotlightVideosUpdateSpotlightVideoRequest, options?: AxiosRequestConfig) {
        return SpotlightVideosApiFp(this.configuration).spotlightVideosUpdateSpotlightVideo(requestParameters.spotlight, requestParameters.body, requestParameters.updateMask, options).then((request) => request(this.axios, this.basePath));
    }
}


