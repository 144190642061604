/* tslint:disable */
/* eslint-disable */
/**
 * AuditSLI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AiAuditSLI
 */
export interface AiAuditSLI {
    /**
     * 
     * @type {number}
     * @memberof AiAuditSLI
     */
    'numberOfAudits'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiAuditSLI
     */
    'numberOfWorkerDeviations'?: number;
    /**
     * 
     * @type {AiKind}
     * @memberof AiAuditSLI
     */
    'presence'?: AiKind;
    /**
     * 
     * @type {AiKind}
     * @memberof AiAuditSLI
     */
    'order'?: AiKind;
}
/**
 * 
 * @export
 * @interface AiAuditSLIsRequestPayload
 */
export interface AiAuditSLIsRequestPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof AiAuditSLIsRequestPayload
     */
    'procedures'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AiAuditSLIsResponse
 */
export interface AiAuditSLIsResponse {
    /**
     * 
     * @type {{ [key: string]: AiAuditSLI; }}
     * @memberof AiAuditSLIsResponse
     */
    'auditSlis'?: { [key: string]: AiAuditSLI; };
}
/**
 * 
 * @export
 * @interface AiConfusionMatrix
 */
export interface AiConfusionMatrix {
    /**
     * 
     * @type {number}
     * @memberof AiConfusionMatrix
     */
    'truePositives'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiConfusionMatrix
     */
    'trueNegatives'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiConfusionMatrix
     */
    'falsePositives'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiConfusionMatrix
     */
    'falseNegatives'?: number;
}
/**
 * 
 * @export
 * @interface AiKind
 */
export interface AiKind {
    /**
     * 
     * @type {number}
     * @memberof AiKind
     */
    'f1Score'?: number;
    /**
     * 
     * @type {AiConfusionMatrix}
     * @memberof AiKind
     */
    'confusionMatrix'?: AiConfusionMatrix;
    /**
     * 
     * @type {AiRates}
     * @memberof AiKind
     */
    'rates'?: AiRates;
}
/**
 * 
 * @export
 * @interface AiRates
 */
export interface AiRates {
    /**
     * 
     * @type {number}
     * @memberof AiRates
     */
    'truePositivesRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiRates
     */
    'trueNegativesRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiRates
     */
    'falsePositivesRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiRates
     */
    'falseNegativesRate'?: number;
}
/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * AuditSLIsApi - axios parameter creator
 * @export
 */
export const AuditSLIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} macrostepId 
         * @param {AiAuditSLIsRequestPayload} body 
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [readMask] 
         * @param {number} [numberOfDays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditSLIsListAuditSLIs: async (macrostepId: string, body: AiAuditSLIsRequestPayload, filter?: string, readMask?: string, numberOfDays?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'macrostepId' is not null or undefined
            assertParamExists('auditSLIsListAuditSLIs', 'macrostepId', macrostepId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('auditSLIsListAuditSLIs', 'body', body)
            const localVarPath = `/v1/audit_sli/macrostep/{macrostepId}`
                .replace(`{${"macrostepId"}}`, encodeURIComponent(String(macrostepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (readMask !== undefined) {
                localVarQueryParameter['readMask'] = readMask;
            }

            if (numberOfDays !== undefined) {
                localVarQueryParameter['numberOfDays'] = numberOfDays;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditSLIsApi - functional programming interface
 * @export
 */
export const AuditSLIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditSLIsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} macrostepId 
         * @param {AiAuditSLIsRequestPayload} body 
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [readMask] 
         * @param {number} [numberOfDays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditSLIsListAuditSLIs(macrostepId: string, body: AiAuditSLIsRequestPayload, filter?: string, readMask?: string, numberOfDays?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiAuditSLIsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditSLIsListAuditSLIs(macrostepId, body, filter, readMask, numberOfDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditSLIsApi - factory interface
 * @export
 */
export const AuditSLIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditSLIsApiFp(configuration)
    return {
        /**
         * 
         * @param {AuditSLIsApiAuditSLIsListAuditSLIsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditSLIsListAuditSLIs(requestParameters: AuditSLIsApiAuditSLIsListAuditSLIsRequest, options?: AxiosRequestConfig): AxiosPromise<AiAuditSLIsResponse> {
            return localVarFp.auditSLIsListAuditSLIs(requestParameters.macrostepId, requestParameters.body, requestParameters.filter, requestParameters.readMask, requestParameters.numberOfDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for auditSLIsListAuditSLIs operation in AuditSLIsApi.
 * @export
 * @interface AuditSLIsApiAuditSLIsListAuditSLIsRequest
 */
export interface AuditSLIsApiAuditSLIsListAuditSLIsRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditSLIsApiAuditSLIsListAuditSLIs
     */
    readonly macrostepId: string

    /**
     * 
     * @type {AiAuditSLIsRequestPayload}
     * @memberof AuditSLIsApiAuditSLIsListAuditSLIs
     */
    readonly body: AiAuditSLIsRequestPayload

    /**
     * comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
     * @type {string}
     * @memberof AuditSLIsApiAuditSLIsListAuditSLIs
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof AuditSLIsApiAuditSLIsListAuditSLIs
     */
    readonly readMask?: string

    /**
     * 
     * @type {number}
     * @memberof AuditSLIsApiAuditSLIsListAuditSLIs
     */
    readonly numberOfDays?: number
}

/**
 * AuditSLIsApi - object-oriented interface
 * @export
 * @class AuditSLIsApi
 * @extends {BaseAPI}
 */
export class AuditSLIsApi extends BaseAPI {
    /**
     * 
     * @param {AuditSLIsApiAuditSLIsListAuditSLIsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditSLIsApi
     */
    public auditSLIsListAuditSLIs(requestParameters: AuditSLIsApiAuditSLIsListAuditSLIsRequest, options?: AxiosRequestConfig) {
        return AuditSLIsApiFp(this.configuration).auditSLIsListAuditSLIs(requestParameters.macrostepId, requestParameters.body, requestParameters.filter, requestParameters.readMask, requestParameters.numberOfDays, options).then((request) => request(this.axios, this.basePath));
    }
}


