import { defineStore } from 'pinia'
import AuthService from '@/services/AuthService'

import type { UserStoreState } from './types'

interface User {
  sub: string,
  'email_verified': boolean,
  email: string,
  'custom:roles': string[],
  'custom:groups': string[]
}

const USER_ROLES = {
  SUPERVISOR: 'supervisor',
  PERFORMANCE_MANAGER: 'performance-manager',
  AUDITOR: 'auditor',
}

export const useUsersStore = defineStore('usersStore', {
  state: (): UserStoreState => ({
    user: null,
    userGroup: null,
  }),

  getters: {
    isUserWithoutRole: (state) => !state.user?.['custom:roles'],
    isUserPerformanceManager: (state) => state.user?.['custom:roles'].includes(USER_ROLES.PERFORMANCE_MANAGER),
    isUserSupervisor: (state) => state.user?.['custom:roles'].includes(USER_ROLES.SUPERVISOR),
    isUserAuditor: (state) => state.user?.['custom:roles'].includes(USER_ROLES.AUDITOR),

    userId: (state) => state.user?.sub,
    userEmail: (state) => state.user?.email,
  },

  actions: {
    async getCurrentUser() {
      const groups: unknown = await AuthService.getCurrentUserGroups()
      const user: unknown = await AuthService.getCurrentUser()
      this.setUser(user as User)
      this.setGroups(groups as string[])
    },

    setUser(payload: User) {
      this.user = payload
    },

    setGroups(payload: string | string[]) {
      this.userGroup = payload
    },
  },
})
