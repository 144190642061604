/* tslint:disable */
/* eslint-disable */
/**
 * DevResolution
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AiDevResolution
 */
export interface AiDevResolution {
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'lastModified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AiDevResolution
     */
    'multipleUnits'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AiDevResolution
     */
    'earlyStart'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AiDevResolution
     */
    'earlyStop'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'procedure'?: string;
    /**
     * 
     * @type {Array<AiStep>}
     * @memberof AiDevResolution
     */
    'steps'?: Array<AiStep>;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'additionalNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'factory'?: string;
    /**
     * Used to know who submitted the deviation resolution.
     * @type {string}
     * @memberof AiDevResolution
     */
    'auditor'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'auditSubmissionTime'?: string;
    /**
     * 
     * @type {DevResolutionResolutionStatus}
     * @memberof AiDevResolution
     */
    'resolutionStatus'?: DevResolutionResolutionStatus;
    /**
     * 
     * @type {Array<AiFrameSelection>}
     * @memberof AiDevResolution
     */
    'manualSelections'?: Array<AiFrameSelection>;
    /**
     * 
     * @type {boolean}
     * @memberof AiDevResolution
     */
    'videoNotLoading'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AiDevResolution
     */
    'otherError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AiDevResolution
     */
    'auditStartTime'?: string;
    /**
     * 
     * @type {DevResolutionStepAiResultsStatus}
     * @memberof AiDevResolution
     */
    'stepAiResultsStatus'?: DevResolutionStepAiResultsStatus;
}


/**
 * 
 * @export
 * @interface AiFrameSelection
 */
export interface AiFrameSelection {
    /**
     * 
     * @type {number}
     * @memberof AiFrameSelection
     */
    'unitVideoSec'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFrameSelection
     */
    'sessionVideoPts'?: number;
}
/**
 * 
 * @export
 * @interface AiListDevResolutionsResponse
 */
export interface AiListDevResolutionsResponse {
    /**
     * 
     * @type {Array<AiDevResolution>}
     * @memberof AiListDevResolutionsResponse
     */
    'devResolutions'?: Array<AiDevResolution>;
    /**
     * 
     * @type {string}
     * @memberof AiListDevResolutionsResponse
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface AiStep
 */
export interface AiStep {
    /**
     * 
     * @type {string}
     * @memberof AiStep
     */
    'stepId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'notDeviated'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'outOfOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'missing'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'aiDetectedNotDeviated'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'aiDetectedOutOfOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiStep
     */
    'aiDetectedMissing'?: number;
}
/**
 *  - UNKNOWN: Used when instantiated, indicates that the step was not fully initialized.  - ACTIVE: Used for when the deviation is in progress/active.
 * @export
 * @enum {string}
 */

export const DevResolutionResolutionStatus = {
    Unknown: 'UNKNOWN',
    Incomplete: 'INCOMPLETE',
    Active: 'ACTIVE',
    Complete: 'COMPLETE'
} as const;

export type DevResolutionResolutionStatus = typeof DevResolutionResolutionStatus[keyof typeof DevResolutionResolutionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DevResolutionStepAiResultsStatus = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;

export type DevResolutionStepAiResultsStatus = typeof DevResolutionStepAiResultsStatus[keyof typeof DevResolutionStepAiResultsStatus];


/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * DevResolutionsApi - axios parameter creator
 * @export
 */
export const DevResolutionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AiDevResolution} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsCreateDevResolution: async (body: AiDevResolution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('devResolutionsCreateDevResolution', 'body', body)
            const localVarPath = `/v1/devresolutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsDeleteDevResolution: async (devresolution: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'devresolution' is not null or undefined
            assertParamExists('devResolutionsDeleteDevResolution', 'devresolution', devresolution)
            const localVarPath = `/v1/devresolutions/{devresolution}`
                .replace(`{${"devresolution"}}`, encodeURIComponent(String(devresolution)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsGetDevResolution: async (devresolution: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'devresolution' is not null or undefined
            assertParamExists('devResolutionsGetDevResolution', 'devresolution', devresolution)
            const localVarPath = `/v1/devresolutions/{devresolution}`
                .replace(`{${"devresolution"}}`, encodeURIComponent(String(devresolution)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsListDevResolutions: async (pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/devresolutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (readMask !== undefined) {
                localVarQueryParameter['readMask'] = readMask;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {AiDevResolution} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsUpdateDevResolution: async (devresolution: string, body: AiDevResolution, updateMask?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'devresolution' is not null or undefined
            assertParamExists('devResolutionsUpdateDevResolution', 'devresolution', devresolution)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('devResolutionsUpdateDevResolution', 'body', body)
            const localVarPath = `/v1/devresolutions/{devresolution}`
                .replace(`{${"devresolution"}}`, encodeURIComponent(String(devresolution)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (updateMask !== undefined) {
                localVarQueryParameter['updateMask'] = updateMask;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevResolutionsApi - functional programming interface
 * @export
 */
export const DevResolutionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevResolutionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AiDevResolution} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devResolutionsCreateDevResolution(body: AiDevResolution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiDevResolution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devResolutionsCreateDevResolution(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devResolutionsDeleteDevResolution(devresolution: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devResolutionsDeleteDevResolution(devresolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devResolutionsGetDevResolution(devresolution: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiDevResolution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devResolutionsGetDevResolution(devresolution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] How many events to return per page.
         * @param {string} [pageToken] Used to retrieve the next page.
         * @param {string} [filter] comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
         * @param {string} [orderBy] order by description ref: https://google.aip.dev/160 * manufacturer * line.
         * @param {string} [readMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devResolutionsListDevResolutions(pageSize?: number, pageToken?: string, filter?: string, orderBy?: string, readMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiListDevResolutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devResolutionsListDevResolutions(pageSize, pageToken, filter, orderBy, readMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} devresolution 
         * @param {AiDevResolution} body 
         * @param {string} [updateMask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devResolutionsUpdateDevResolution(devresolution: string, body: AiDevResolution, updateMask?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiDevResolution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devResolutionsUpdateDevResolution(devresolution, body, updateMask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevResolutionsApi - factory interface
 * @export
 */
export const DevResolutionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevResolutionsApiFp(configuration)
    return {
        /**
         * 
         * @param {DevResolutionsApiDevResolutionsCreateDevResolutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsCreateDevResolution(requestParameters: DevResolutionsApiDevResolutionsCreateDevResolutionRequest, options?: AxiosRequestConfig): AxiosPromise<AiDevResolution> {
            return localVarFp.devResolutionsCreateDevResolution(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DevResolutionsApiDevResolutionsDeleteDevResolutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsDeleteDevResolution(requestParameters: DevResolutionsApiDevResolutionsDeleteDevResolutionRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.devResolutionsDeleteDevResolution(requestParameters.devresolution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DevResolutionsApiDevResolutionsGetDevResolutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsGetDevResolution(requestParameters: DevResolutionsApiDevResolutionsGetDevResolutionRequest, options?: AxiosRequestConfig): AxiosPromise<AiDevResolution> {
            return localVarFp.devResolutionsGetDevResolution(requestParameters.devresolution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DevResolutionsApiDevResolutionsListDevResolutionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsListDevResolutions(requestParameters: DevResolutionsApiDevResolutionsListDevResolutionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AiListDevResolutionsResponse> {
            return localVarFp.devResolutionsListDevResolutions(requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DevResolutionsApiDevResolutionsUpdateDevResolutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devResolutionsUpdateDevResolution(requestParameters: DevResolutionsApiDevResolutionsUpdateDevResolutionRequest, options?: AxiosRequestConfig): AxiosPromise<AiDevResolution> {
            return localVarFp.devResolutionsUpdateDevResolution(requestParameters.devresolution, requestParameters.body, requestParameters.updateMask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for devResolutionsCreateDevResolution operation in DevResolutionsApi.
 * @export
 * @interface DevResolutionsApiDevResolutionsCreateDevResolutionRequest
 */
export interface DevResolutionsApiDevResolutionsCreateDevResolutionRequest {
    /**
     * 
     * @type {AiDevResolution}
     * @memberof DevResolutionsApiDevResolutionsCreateDevResolution
     */
    readonly body: AiDevResolution
}

/**
 * Request parameters for devResolutionsDeleteDevResolution operation in DevResolutionsApi.
 * @export
 * @interface DevResolutionsApiDevResolutionsDeleteDevResolutionRequest
 */
export interface DevResolutionsApiDevResolutionsDeleteDevResolutionRequest {
    /**
     * 
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsDeleteDevResolution
     */
    readonly devresolution: string
}

/**
 * Request parameters for devResolutionsGetDevResolution operation in DevResolutionsApi.
 * @export
 * @interface DevResolutionsApiDevResolutionsGetDevResolutionRequest
 */
export interface DevResolutionsApiDevResolutionsGetDevResolutionRequest {
    /**
     * 
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsGetDevResolution
     */
    readonly devresolution: string
}

/**
 * Request parameters for devResolutionsListDevResolutions operation in DevResolutionsApi.
 * @export
 * @interface DevResolutionsApiDevResolutionsListDevResolutionsRequest
 */
export interface DevResolutionsApiDevResolutionsListDevResolutionsRequest {
    /**
     * How many events to return per page.
     * @type {number}
     * @memberof DevResolutionsApiDevResolutionsListDevResolutions
     */
    readonly pageSize?: number

    /**
     * Used to retrieve the next page.
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsListDevResolutions
     */
    readonly pageToken?: string

    /**
     * comma delimited filters ref: https://google.aip.dev/160 * Line no (future).
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsListDevResolutions
     */
    readonly filter?: string

    /**
     * order by description ref: https://google.aip.dev/160 * manufacturer * line.
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsListDevResolutions
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsListDevResolutions
     */
    readonly readMask?: string
}

/**
 * Request parameters for devResolutionsUpdateDevResolution operation in DevResolutionsApi.
 * @export
 * @interface DevResolutionsApiDevResolutionsUpdateDevResolutionRequest
 */
export interface DevResolutionsApiDevResolutionsUpdateDevResolutionRequest {
    /**
     * 
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsUpdateDevResolution
     */
    readonly devresolution: string

    /**
     * 
     * @type {AiDevResolution}
     * @memberof DevResolutionsApiDevResolutionsUpdateDevResolution
     */
    readonly body: AiDevResolution

    /**
     * 
     * @type {string}
     * @memberof DevResolutionsApiDevResolutionsUpdateDevResolution
     */
    readonly updateMask?: string
}

/**
 * DevResolutionsApi - object-oriented interface
 * @export
 * @class DevResolutionsApi
 * @extends {BaseAPI}
 */
export class DevResolutionsApi extends BaseAPI {
    /**
     * 
     * @param {DevResolutionsApiDevResolutionsCreateDevResolutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevResolutionsApi
     */
    public devResolutionsCreateDevResolution(requestParameters: DevResolutionsApiDevResolutionsCreateDevResolutionRequest, options?: AxiosRequestConfig) {
        return DevResolutionsApiFp(this.configuration).devResolutionsCreateDevResolution(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DevResolutionsApiDevResolutionsDeleteDevResolutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevResolutionsApi
     */
    public devResolutionsDeleteDevResolution(requestParameters: DevResolutionsApiDevResolutionsDeleteDevResolutionRequest, options?: AxiosRequestConfig) {
        return DevResolutionsApiFp(this.configuration).devResolutionsDeleteDevResolution(requestParameters.devresolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DevResolutionsApiDevResolutionsGetDevResolutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevResolutionsApi
     */
    public devResolutionsGetDevResolution(requestParameters: DevResolutionsApiDevResolutionsGetDevResolutionRequest, options?: AxiosRequestConfig) {
        return DevResolutionsApiFp(this.configuration).devResolutionsGetDevResolution(requestParameters.devresolution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DevResolutionsApiDevResolutionsListDevResolutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevResolutionsApi
     */
    public devResolutionsListDevResolutions(requestParameters: DevResolutionsApiDevResolutionsListDevResolutionsRequest = {}, options?: AxiosRequestConfig) {
        return DevResolutionsApiFp(this.configuration).devResolutionsListDevResolutions(requestParameters.pageSize, requestParameters.pageToken, requestParameters.filter, requestParameters.orderBy, requestParameters.readMask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DevResolutionsApiDevResolutionsUpdateDevResolutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevResolutionsApi
     */
    public devResolutionsUpdateDevResolution(requestParameters: DevResolutionsApiDevResolutionsUpdateDevResolutionRequest, options?: AxiosRequestConfig) {
        return DevResolutionsApiFp(this.configuration).devResolutionsUpdateDevResolution(requestParameters.devresolution, requestParameters.body, requestParameters.updateMask, options).then((request) => request(this.axios, this.basePath));
    }
}


