import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

import * as mocksDefinitions from '@/network/mocks'

import { CamerasApi, Configuration } from '@/api/cameras'
import { ProceduresApi } from '@/api/procedures'
import { ReportsApi } from '@/api/reports'
import { StationsApi } from '@/api/stations'
import { StepsApi } from '@/api/steps'
import { UnitsApi } from '@/api/units'
import { InferencesApi } from '@/api/inferences'
import { AnalyticsApi } from '@/api/analytics'
import { SessionsApi } from '@/api/sessions'
import { VideosApi } from '@/api/videos'
import { KpiResultsApi } from '@/api/kpi_result'
import { SpotlightVideosApi } from '@/api/spotlight_video'
import { DevResolutionsApi } from '@/api/dev_resolution'
import { AuditSLIsApi } from '@/api/audit_sli'
import { CaptchaImagesApi } from '@/api/captchaImage'

import AuthService from '@/services/AuthService'

import { Auth } from 'aws-amplify'
Auth.configure({})

// Quick fix to remove TS errors
declare global {
  interface Window {
    configs: {
      NFX_CD_API: string
      NFX_CD_MLOPS_API: string
    }
  }
}

const apiConfig = new Configuration({
  basePath: window?.configs?.NFX_CD_API || window.location.origin,
})

const mlopsConfig = new Configuration({
  basePath: window?.configs?.NFX_CD_MLOPS_API || window.location.origin,
})

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

if (isDevelopmentEnv) {
  const noop = () => ({})
  const mockAdapter = new MockAdapter(axios, { onNoMatch: 'passthrough' })
  const mocks = Object.values(mocksDefinitions)

  mocks.forEach(async mock => {
    const { url, method = 'GET', responseStatus = 200, responseBody = noop } = mock
    const response = await responseBody()

    const isPostMethod = method === 'POST'
    if (isPostMethod) {
      mockAdapter.onPost(url).reply(responseStatus, response)
    } else {
      mockAdapter.onGet(url).reply(responseStatus, response)
    }
  })
}

axios.interceptors.request.use(async req => {
  const isRequestUrlMeetsApiBasePath = req.url?.startsWith(apiConfig.basePath as string)
  const isRequestUrlMeetsMlopsBasePath = req.url?.startsWith(mlopsConfig.basePath as string)
  const isRequestUrlMeetsTempOnDemandPath = req.url?.includes('cloud-dashboard.api.d.pma.nflux.ai')
  if (req.url && (isRequestUrlMeetsApiBasePath || isRequestUrlMeetsMlopsBasePath || isRequestUrlMeetsTempOnDemandPath)) {
    const authToken = await AuthService.getToken()
    // const idToken = await AuthService.getIdToken()
    req.headers.Authorization = `Bearer ${authToken}`
    // req.headers['X-Nfx-Api-Id-Token'] = idToken
  }
  return req
})

export const api = {
  devResolutions: new DevResolutionsApi(mlopsConfig),
  spotlightVideos: new SpotlightVideosApi(mlopsConfig),
  kpiResults: new KpiResultsApi(mlopsConfig),
  slis: new AuditSLIsApi(mlopsConfig),
  cameras: new CamerasApi(apiConfig),
  procedures: new ProceduresApi(apiConfig),
  reports: new ReportsApi(apiConfig),
  stations: new StationsApi(apiConfig),
  steps: new StepsApi(apiConfig),
  inferences: new InferencesApi(apiConfig),
  units: new UnitsApi(apiConfig),
  analytics: new AnalyticsApi(apiConfig),
  sessions: new SessionsApi(apiConfig),
  videos: new VideosApi(apiConfig),
  captchas: new CaptchaImagesApi(apiConfig),

  apiBasePath: apiConfig.basePath,
  mlopsBasePath: mlopsConfig.basePath,
}
