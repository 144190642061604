
import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  components: { },
  name: 'Bar Menu',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
  setup() {
    const show = ref(false)

    const panel = ref(null)
    onClickOutside(panel, () => {
      show.value = false
    })

    return {
      show,
      panel,
    }
  },
})
