
import { defineComponent, ref, computed, PropType } from 'vue'
import { onClickOutside } from '@vueuse/core'
import moment from 'moment'
import Settings from './Settings.vue'
import BarMenu from './BarMenu.vue'
import Search from './Search.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuth0 } from '@auth0/auth0-vue'
import { useUserAuthStore } from '@/stores/auth/auth'

export default defineComponent({
  components: { Settings, BarMenu, Search },
  name: 'Navbar',
  watch: {
    '$route'() {
      this.closeNav()
    },
  },
  props: {
    settings: {
      type: Object as PropType<Record<string, string>>,
    },
  },
  setup() {
    const user = { firstname: 'Jim', lastname: 'John' }
    const timeFmt = 'dddd, MMMM Do h:mm:ss A'

    const name = computed(() => `${user.firstname[0]}. ${user.lastname}`)
    const time = ref(moment().format(timeFmt))

    const nav = ref(null)
    const navOpen = ref(false)
    const closeNav = () => { navOpen.value = false }
    const toggleNav = () => { navOpen.value = !navOpen.value }

    const userAuth = useUserAuthStore()
    const auth0 = useAuth0()

    onClickOutside(nav, (evt) => {
      evt.stopPropagation()
      closeNav()
    })

    // refresh time string
    setInterval(() => {
      time.value = moment().format(timeFmt)
    }, 1000)

    const logout = async () => {
      userAuth.setAccessToken(null)

      await auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    }

    return { name, time, nav, navOpen, closeNav, toggleNav, logout }
  },
})
