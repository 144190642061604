import { ref } from 'vue'
import { defineStore } from 'pinia'

export interface IdClaims {
  sub: string | undefined,
  'email_verified': boolean | undefined,
  email: string | undefined,
  'custom:roles': string[] | undefined,
  'custom:groups': string[] | undefined,
}

export const useUserAuthStore = defineStore('auth', () => {
  // const idToken = ref(null as string | null)
  const accessToken = ref(null as string | null)
  const idClaims = ref(null as IdClaims | null)

  // function setIdToken(token: string | null) {
  //   idToken.value = token || null
  // }

  function setAccessToken(token: string | null) {
    accessToken.value = token || null
  }

  function setIdClaims(claims: IdClaims | null) {
    idClaims.value = claims || null
  }

  return {
    // idToken,
    // setIdToken,
    accessToken,
    setAccessToken,
    idClaims,
    setIdClaims,
  }
})
